<script>
export default {
  inheritAttrs: false,
}
</script>
<script setup>
import { Link } from "@inertiajs/vue3"
import { computed } from "vue"
const props = defineProps({
  withoutInertia: {
    type: Boolean,
    default: false,
  },
})

const component = computed(() => (props.withoutInertia ? "a" : Link))
</script>

<template>
  <component
    :is="component"
    v-bind="$attrs"
    class="leading-5 font-medium text-sm text-cerulean-600 hover:text-cerulean-900"
  >
    <slot />
  </component>
</template>
