<template>
  <div class="w-full sm:max-w-lg mt-6 mb-3 overflow-hidden">
    <nav class="isolate flex" aria-label="Tabs">
      <Link
        without-inertia
        v-for="lang in langs"
        :key="lang.name"
        :href="`?set_lang=${lang.code}`"
        :class="[
          lang.current
            ? 'text-cerulean-900'
            : 'text-cerulean-600 hover:text-cerulean-700',
          'group relative min-w-0 flex-1 overflow-hidden leading-5 py-4 px-1 text-sm font-medium text-center focus:z-10',
        ]"
        :aria-current="lang.current ? 'page' : undefined"
      >
        <span>{{ lang.name }}</span>
      </Link>
    </nav>
  </div>
</template>

<script setup>
import Link from "@/Components/Link.vue"

const pageLanguage = document.querySelector("html").getAttribute("lang")

const langs = [
  { name: "English", code: "en", current: pageLanguage === "en" },
  { name: "Español", code: "es", current: pageLanguage === "es" },
  { name: "中文", code: "zh", current: pageLanguage === "zh" },
  { name: "한국어", code: "ko", current: pageLanguage === "ko" },
  { name: "Tiếng Việt", code: "vi", current: pageLanguage === "vi" },
]
</script>
